import { BASE_API_URL } from 'src/utils/app-constants'

export const AGENT_URL = `${BASE_API_URL}organisation`
export const RIDER_URL = `${BASE_API_URL}organisation?organisation_type=rider`
export const SUPPLIER_URL = `${BASE_API_URL}organisation?organisation_type=supplier`
export const HANDLERS_URL = `${BASE_API_URL}organisation?organisation_type=handler`
export const USER_URL = `${BASE_API_URL}user`
export const LOGIN_URL = `${BASE_API_URL}auth/login`
export const PRODUCT_URL = `${BASE_API_URL}product`
export const PRODUCT_SKU_URL = `${BASE_API_URL}product-sku`
export const PRODUCT_URL_TYPE = `${BASE_API_URL}producttype`
export const ROLES_URL = `${BASE_API_URL}role`
export const UNITS_URL = `${BASE_API_URL}unit`
export const ORGANISATION_CATEGORY_URL = `${BASE_API_URL}organisationcategory`
export const ORGANISATION_CATEGORY_TYPE_URL = `${BASE_API_URL}organisationcategorytype`
export const TRUCK_URL = `${BASE_API_URL}truck`
export const COOLBOX_URL = `${BASE_API_URL}coolbox`
export const MAINTENANCE_URL = `${BASE_API_URL}maintenancetype`
export const APPUSER_URL = `${BASE_API_URL}appuser`
export const ORDER_URL = `${BASE_API_URL}order`
export const ORDER_RETURN_REASON = `${BASE_API_URL}order-return-reason`
export const DOCUMENT_URL = `${BASE_API_URL}organisationdocument`
export const DOCUMENT_TYPE_URL = `${BASE_API_URL}documenttype`
export const DISCOUNT_URL = `${BASE_API_URL}discount`
export const FEEDBACK_URL = `${BASE_API_URL}feedback`
export const LOCATION_URL = `${BASE_API_URL}organisationlocation`
export const ORG_BANK_URL = `${BASE_API_URL}organisationbank`
export const PRODUCTS_OFFERED_URL = `${BASE_API_URL}organisationproduct`
export const PAYMENT_URL = `${BASE_API_URL}payment`
export const STATS_URL = `${BASE_API_URL}stats`
export const DISPATCH_URL = `${BASE_API_URL}dispatch`
export const LOAD_REQUEST_URL = `${BASE_API_URL}stock-transfer`
export const WAREHOUSES_URL = `${BASE_API_URL}warehouse`
export const INVOICE_URL = `${BASE_API_URL}invoice`
export const RETURNS_URL = `${BASE_API_URL}order-return`
export const COUNTRIES_URL = `${BASE_API_URL}country`
export const REGIONS_URL = `${BASE_API_URL}region`
export const AREAS_URL = `${BASE_API_URL}area`
export const ZONES_URL = `${BASE_API_URL}zone`
export const ROUTES_URL = `${BASE_API_URL}route`
export const CREDIT_NOTES_URL = `${BASE_API_URL}credit-note`
export const SOURCED_PRODUCTS_URL = `${BASE_API_URL}sourcing-product`
export const CURRENCY_URL = `${BASE_API_URL}currency`
export const BANK_URL = `${BASE_API_URL}bank`
export const BANK_BRANCH_URL = `${BASE_API_URL}bank-branch`
export const INVOICE_PAYMENT_URL = `${BASE_API_URL}invoice-payment`
export const RECEIPT_URL = `${BASE_API_URL}payment-receipt`
export const DISPATCH_ITEM_URL = `${BASE_API_URL}dispatch-item`
export const NOTIFICATIONS_URL = `${BASE_API_URL}notification`
export const SHRIKAGE_URL = `${BASE_API_URL}stock-shrinkage`
export const WAREHOUSE_PRODUCTS_URL = `${BASE_API_URL}warehouse-product`
export const WAREHOUSE_PRODUCT_LOGS_URL = `${BASE_API_URL}warehouse-product-log`
export const ROUTES_SCHEDULE_URL = `${BASE_API_URL}route-schedule`
export const ORGANISATION_PAYMENT_TERMS = `${BASE_API_URL}organisationpaymentterm`
export const PAYOUT_URLS = `${BASE_API_URL}commissionpayout`
export const PRODUCT_GROUP_URL = `${BASE_API_URL}productgroup`
export const SETTINGS_URL = `${BASE_API_URL}settings`
export const DISPATCH_ITEM_REPORT = `${BASE_API_URL}dispatch-item-report`
export const DISPATCH_ITEM_SUMMARY = `${BASE_API_URL}dispatch-item-summary`
export const ORDER_POOL_URL = `${BASE_API_URL}order-pool`
export const COMMISSION_RULES_URL = `${BASE_API_URL}commission-rule`
export const COMMISSION_RULES_URL_TIERS = `${BASE_API_URL}commission-rule-tier`
export const ASSIGN_COMMISSION_URL = `${BASE_API_URL}agent-commission`
//name, units, product_id.

//feedback
